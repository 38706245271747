<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <!-- <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.slug"
            :error-messages="slugErrors"
            :label="$t('slug')"
            @input="$v.item.slug.$touch()"
            @blur="$v.item.slug.$touch()"
          />
        </v-col> -->

        <v-col  offset-md="3" cols="6" sm="6" md="6">
          <v-text-field
            v-model="item.nom"
            :error-messages="nomErrors"
            :label="$t('Nom')"
            required
            @input="$v.item.nom.$touch()"
            @blur="$v.item.nom.$touch()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col offset-md="3" cols="3" sm="3" md="3">
          <v-text-field
            v-model="item.codePostal"
            :error-messages="codePostalErrors"
            :label="$t('Code Postal')"
            required
            @input="$v.item.codePostal.$touch()"
            @blur="$v.item.codePostal.$touch()"
          />
        </v-col>

        <v-col cols="3" sm="3" md="3">
          <v-text-field
            v-model="item.adresse"
            :error-messages="adresseErrors"
            :label="$t('Adresse')"
            required
            @input="$v.item.adresse.$touch()"
            @blur="$v.item.adresse.$touch()"
          />
        </v-col>

        
      </v-row>

      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.ville"
            :error-messages="villeErrors"
            :label="$t('Ville')"
            required
            @input="$v.item.ville.$touch()"
            @blur="$v.item.ville.$touch()"
          />
        </v-col>

        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-select
            v-model="item.evenements"
            :items="evenementsSelectItems"
            :error-messages="evenementsErrors"
            :no-data-text="$t('No results')"
            :label="$t('Evenement')"
            multiple
            item-text="nom"
            item-value="@id"
            chips
            close
            @click:close="chips = false"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from "lodash/has";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "SiteForm",
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true,
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.evenementsGetSelectItems();
  },
  data() {
    return {
      slug: null,
      nom: null,
      adresse: null,
      codePostal: null,
      ville: null,
      evenements: null
    };
  },
  computed: {
    ...mapFields("evenement", {
      evenementsSelectItems: "selectItems"
    }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    slugErrors() {
      const errors = [];

      if (!this.$v.item.slug.$dirty) return errors;

      has(this.violations, "slug") && errors.push(this.violations.slug);

      return errors;
    },
    nomErrors() {
      const errors = [];

      if (!this.$v.item.nom.$dirty) return errors;

      has(this.violations, "nom") && errors.push(this.violations.nom);

      !this.$v.item.nom.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    adresseErrors() {
      const errors = [];

      if (!this.$v.item.adresse.$dirty) return errors;

      has(this.violations, "adresse") && errors.push(this.violations.adresse);

      !this.$v.item.adresse.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    codePostalErrors() {
      const errors = [];

      if (!this.$v.item.codePostal.$dirty) return errors;

      has(this.violations, "codePostal") &&
        errors.push(this.violations.codePostal);

      !this.$v.item.codePostal.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    villeErrors() {
      const errors = [];

      if (!this.$v.item.ville.$dirty) return errors;

      has(this.violations, "ville") && errors.push(this.violations.ville);

      !this.$v.item.ville.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    evenementsErrors() {
      const errors = [];

      if (!this.$v.item.evenements.$dirty) return errors;

      has(this.violations, "evenements") &&
        errors.push(this.violations.evenements);

      return errors;
    },

    violations() {
      return this.errors || {};
    }
  },
  methods: {
    ...mapActions({
      evenementsGetSelectItems: "evenement/fetchSelectItems"
    })
  },
  validations: {
    item: {
      slug: {},
      nom: {
        required
      },
      adresse: {
        required
      },
      codePostal: {
        required
      },
      ville: {
        required
      },
      evenements: {}
    }
  }
};
</script>
